@import "components/variables";


.quality__item {
    text-align: center;

    margin-bottom: 30px;
}

.quality__figure {
    width: 100%;
    height: 85px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.quality__title {
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 400;
}

.quality {
    padding-bottom: 30px;
    padding-top: 70px;
}

.quality__wrapper {
    padding-top: 20px;


}


.pn {
    border: 1px solid $line;
    padding: 30px 20px 0 20px;

    margin-bottom: 50px;

    @media(min-width:$grid-breakpoints-xl) {
        padding: 35px 50px;
    }

    .similar {
        padding-bottom: 0;
        padding-top: 0;
    }
}

.pn__top {
    text-align: center;
    margin-bottom: 20px;

    .title--4 {
        text-align: left;

        span {
            color: $main;
        }
    }

    .tabs__navlist {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    @media(min-width:$grid-breakpoints-xl) {


        margin-left: auto;
        margin-right: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
    }

}


.blog {
    padding-top: 50px;
    padding-bottom: 30px;
    overflow: hidden;
}


.blog__wrapper {
    position: relative;   


    // &:before {
    //     content: "";
    //     display: block;
    //     width: 190px;
    //     height: 230px;
    //     border-left: 1px solid $line;
    //     border-top: 1px solid $line;
    //     position: absolute;
    //     top: 0px;
    //     left: -20px;
    //     z-index: -1;
    // }

    // &:after {
    //     content: "";
    //     display: block;
    //     width: 190px;
    //     height: 230px;
    //     border-right: 1px solid $line;
    //     border-bottom: 1px solid $line;
    //     position: absolute;
    //     bottom: 30px;
    //     right: -20px;
    //     z-index: -1;
    // }
}

.blog__link {
    color: $txt;
    font-size: 14px;
    padding-bottom: 20px;
    display: block;

    p {
        max-width: 300px;
    }

    @media(hover:hover) {

        &:hover {
            color: $txt;

            .blog__figure {
                box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.15);
            }

            .blog__title {
                color: $main;
            }

            .btn--trans {
                &:after {
                    content: url("../img/arrow-right-green.svg");
                }

                color:$main;
            }
        }
    }

    @media(max-width:$grid-breakpoints-md) {
        padding-bottom: 50px;
    }
}

.blog__title {
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 10px;
    transition: all 0.3s ease-out;
    font-size: 15px;
}

.blog__figure {
    position: relative;
    height: 0;
    padding-top: 100%;
    margin-bottom: 25px;
    transition: all 0.3s ease-out;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: absolute;

    }
}


.scroll-down {
    position: absolute;
    bottom: 45px;
    left: -20px;
    font-size: 10px;
    color: $txt;
    transform: rotate(-90deg);
    text-transform: uppercase;
    font-weight: 700;
    display: block;
    z-index: 20;
    padding: 10px 20px 10px 30px;
    background-image: url("../img/arrow-scroll.svg");
    background-position: 10px 10px;
    background-repeat: no-repeat;

    @media(min-width:$grid-breakpoints-sm) {
        left: 40px;
    }

    @media(min-width:1480px) {
        left: calc(50vw - 682px);
    }


}

.mainSwiper {
    border-bottom: 1px solid $txt;
    margin-top: 40px;
    position: relative;
}

.mainSwiper__figure {
    position: absolute;
    top: 0;
    left: 50%;
    height: 100%;
    width: 0;
    transition: width 0.3s ease-out;
    overflow: hidden;

    img {
        position: absolute;
        top: 0;
        left: -50px;
        width: 50vw;
        height: 100%;
        object-fit: cover;
        transition: left 0.3s ease-out;
    }
}

.mainSwiper__figure2 {
    position: absolute;
    bottom: 0;
    left: calc(50% - 480px);
    width: 0;
    z-index: -1;
    overflow: hidden;
    transition: width 0.3s ease-out;
    height: 270px;

    img {
        position: absolute;
        top: 0;
        left: -50px;
        width: 500px;
        height: 100%;
        object-fit: cover;
        transition: left 0.3s ease-out;
    }
}

.mainSwiper__slide {
    height: 790px;
    max-height: 90vh;
    min-height: 600px;
    overflow: hidden;
    background: #fff;

    .container {
        height: 100%;
        position: relative;
        z-index: 10;

        @media(max-width:$grid-breakpoints-xl) {
            max-width: 100%;
        }

        &:before {
            content: "";
            display: block;
            width: 1px;
            height: 100%;
            left: 20px;
            top: 0;
            background: $line;
            position: absolute;
            z-index: -1;
        }
    }

    .title--4 {
        text-align: left;
        transition: all 0.3s ease-out;
        position: relative;
    }

    .btn {
        margin-top: 40px;
        position: relative;
    }

    .title--4 span {
        color: $main;
    }
}

.mainSwiper__des {
    padding-bottom: 20px;
}

.mainSwiper__nr {
    font-size: 15px;
    font-weight: 900;
    margin-bottom: 25px;
    display: block;
    width: 200px;
    font-weight: 200;
}


.mainSwiper__txtWr {

    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 80px;
    padding-bottom: 140px;
    width: 100%;
    max-width: 740px;

    .btn {
        margin-top: 20px;
        min-width: 200px;
        margin-bottom: 10px;
    }
}

.mainSwiper__txtWrHidden {
    position: relative;
    width: 0;
    overflow: hidden;
    transition: none;
    padding-bottom: 15px;

    // &:before {
    //     position: absolute;
    //     top: 10px;
    //     right: 0;
    //     height: calc(100% - 25px);
    //     width: 230px;
    //     content: "";
    //     display: block;
    //     z-index: -1;
    //     box-shadow: 2px 2px 15px 0 rgb(0, 0, 0, 0.25);
    // }   
}

.mainSwiper__txtShadow {
    position: relative;

    &:before {
        position: absolute;
        top: 20px;
        right: 0;
        height: calc(100% - 25px);
        width: 70%;
        content: "";
        display: block;
        z-index: -1;
        box-shadow: 2px 2px 15px 0 rgb(0, 0, 0, 0.25);
    }

    width:720px;
}

.mainSwiper__NrHidden {
    width: 0;
    overflow: hidden;
    transition: none;
}

.mainSwiper__LinkHidden {
    width: 0;
    overflow: hidden;
    transition: none;
}

.swiper-buttonHidden {
    width: 0;
    height: 50px;
    overflow: hidden;
    position: relative;
    transition: width 0.3s ease-out;

}



.mainSwiper__txt {
    background: #fff;
    padding-right: 230px;
    z-index: 10;
    position: relative;
    width: 720px;





}




.swiper-slide-active {
    // .mainSwiper__txtWr {
    //     left: 80px;
    //     opacity: 1;
    // }

    // .btn {
    //     left: 0;
    //     opacity: 1;
    // }

    // .mainSwiper__img {
    //     left: 50%;
    //     opacity: 1;

    // }

    // .mainSwiper__img2 {
    //     opacity: 1;
    //     right: 50%;
    // }

    .mainSwiper__txtWrHidden {
        width: 100%;
        transition: width 0.7s ease-out;
    }

    .mainSwiper__NrHidden {
        width: 35px;
        transition: width 0.3s ease-out 0.4s;
    }

    .mainSwiper__LinkHidden {
        width: 220px;
        transition: width 0.3s ease-out 0.4s;
    }

    .mainSwiper__figure {
        width: 50vw;
        transition: width 0.7s ease-out;

        img {
            left: 0;
            transition: left 0.7s ease-out;
        }
    }

    .mainSwiper__figure2 {
        width: 480px;
        transition: width 0.7s ease-out;

        img {
            left: 0;
            transition: left 0.7s ease-out;
        }
    }

    .swiper-buttonHidden {
        width: 60px;

        transition: width 0.7s ease-out;

        .swiper-button-prev {
            right: 0;
        }

        .swiper-button-next {
            left: 0;
        }
    }

}


.swiper-pagination-fraction {
    transform: none;
    right: 20px;
    bottom: 0px;
    font-weight: 900;
    font-size: 25px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 5px;
    position: absolute;
    transform: none !important;
    color: #fff;
}

.swiper-nav2 {
    position: absolute;
    bottom: 250px;
    right: 40px;
    z-index: 10;
    display: flex;
    justify-content: center;
    gap: 20px;
    width: 120px;

    .swiper-button-prev {
        position: absolute;
        right: -10px;
        bottom: 0;

    }

    .swiper-button-next {
        left: 10px;
        position: absolute;
        bottom: 0;
    }

    .swiper-button {
        opacity: 0.5;
    }

    .swiper-buttonHidden {
        @media(hover:hover) {
            &:hover {
                .swiper-button {
                    opacity: 1;
                }
            }
        }
    }
}

.swiper-slide-activeEnd {
    .mainSwiper__figure {
        left: 100%;
        transform: translateX(-110%);
    }
}


.swiper-nav {
    position: absolute;
    bottom: 60px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 30;
    display: flex;
    align-items: center;
    gap: 5px;
}

.swiper-pagination-current {
    font-size: 25px;
}

.swiper-button {
    width: 50px;
    height: 50px;
    display: block;
    cursor: pointer;
    transition: all 0.3s ease-out;

}

.swiper-button-prev {
    background-image: url("../img/swiper-left.svg");
    background-position: center;
    background-repeat: no-repeat;

    @media(min-width:$grid-breakpoints-md) {
        background-image: url("../img/swiper-left-b.svg");
    }
}

.swiper-button-next {
    background-image: url("../img/swiper-right.svg");
    background-position: center;
    background-repeat: no-repeat;

    @media(min-width:$grid-breakpoints-md) {
        background-image: url("../img/swiper-right-b.svg");
    }
}


@media(max-width:$grid-breakpoints-xl) {

    .mainSwiper__txt {
        padding-right: 150px;
        width: 640px;
    }

    .mainSwiper__txtShadow {
        width: 640px;
    }

    .mainSwiper__txtWr {
        max-width: 660px;
    }



}

@media(max-width:$grid-breakpoints-lg) {
    .mainSwiper__txtWr {
        max-width: 720px;
    }

    .mainSwiper__txt {
        width: 720px;
    }

    .mainSwiper__txt {
        width: 700px;
    }
}

@media(max-width:$grid-breakpoints-md) {
    .mainSwiper {
        margin-top: 0px;
    }

    .mainSwiper__slide {
        height: calc(100vh - 150px);
        min-height: 500px;

        .container {
            &:before {
                content: none;
            }
        }

    }

    .mainSwiper__txt {
        width: calc(100vw - 60px);
        padding-right: 0;
    }

    .mainSwiper__txtWr {
        top: 0;
        left: 40px;
        transform: none;
    }

    .mainSwiper__txtShadow:before {
        content: none;
    }



    .mainSwiper__figure {
        top: 270px;
        left: 0;
        height: calc(100% - 270px);

        img {
            width: 100vw;
        }
    }

    .swiper-slide-active .mainSwiper__figure {
        width: 100vw;
    }

    .mainSwiper__figure2 {
        bottom: initial;
        top: -20px;
        left: calc(100% - 170px);
        height: 100px;
        z-index: 30;

        img {
            width: 170px;
            height: 100px;
        }
    }

    .swiper-slide-active .mainSwiper__figure2 {
        width: 170px;

    }

    .mainSwiper__txtWr {

        .title--4 {
            margin-bottom: 20px;
        }

        .btn {
            margin-top: 0px;
        }
    }



    .mainSwiper__des {
        padding-bottom: 0px;
    }

    .mainSwiper__nr {
        font-size: 10px;
        margin-top: 15px;
    }

    .swiper-nav {
        bottom: 10px;
    }

    .swiper-pagination-fraction {
        font-size: 18px;
    }

    .swiper-nav2 {
        top: 80px;
        right: 20px;
        gap: 0
    }

}

@media(max-width:400px) {
    .mainSwiper__txtWr {
        left: 20px;

    }

    .mainSwiper__txt {
        width: calc(100% - 40px);
    }
}




.gallery__wrapper {
    margin-bottom: 60px;

    @media(max-width: $grid-breakpoints-sm) {
        margin-bottom: 40px;
    }
}